body {
    margin: 0;
    font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Roboto",
        "Helvetica Neue", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #FFF !important;
}

::selection {
    background-color: #D5CFFB;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

svg,
img {
    vertical-align: top;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    cursor: pointer;
}

.highlight-dark {
    background-color: #69b1cd !important;
    color: #000 !important;
}

.highlight {
    background-color: #d2f2ff !important;
    color: #000 !important;
}

.strike {
    text-decoration: line-through;
    color: #a31620 !important;
    background-color: #f6e6e7;
}
.text-found {
    background-color: yellow;
}

.highlight {
    background-color: rgba(255, 200, 62, 0.3);
}

@font-face {
    font-family: "HelveticaBold";
    src: url("./fonts/THEBOLDFONT.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

input[type="range"]::-webkit-slider-thumb {
    opacity: 0;
}
input[type="range"]::-moz-range-thumb {
    opacity: 0;
}
input[type="range"]::-ms-thumb {
    opacity: 0;
}

[contenteditable="true"]:empty:before {
    content: attr(placeholder);
    color: #757575;
    pointer-events: none;
}

.link {
    text-decoration: none !important;
    color: #777e91 !important;
}

.active {
    text-decoration: none !important;
    color: #6552f3 !important;
}

.active p {
    color: #6552f3 !important;
    border: 2px solid #6552f3 !important;
}

.grecaptcha-badge {
    visibility: hidden;
}

/* Screen reader only */
.sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
}

/* Ripple effect for react-mic */
@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
    }
}

@keyframes pulse {
    from {
        transform: scale3d(1, 1, 1);
    }

    25% {
        transform: scale3d(1.08, 1.08, 1.08);
    }

    50%,
    100% {
        transform: scale3d(1, 1, 1);
    }
}

@font-face {
    font-family: "The Bold Font";
    src: local("The Bold Font"), url("./fonts/THEBOLDFONT.ttf") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Rubik Black";
    src: local("Rubik Black"), url("./fonts/Rubik-Black.ttf") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "SignPainter HouseSlant";
    src: local("SignPainter HouseSlant"),
        url("./fonts/SignPainterHouseSlantRegular.ttf") format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Impact";
    src: local("Impact"), url("./fonts/impact.ttf") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Satoshi";
    src: local("Satoshi"), url("./fonts/Satoshi-Variable.woff2") format("woff2"),
        url("./fonts/Satoshi-Variable.woff") format("woff"),
        url("./fonts/Satoshi-Variable.ttf") format("truetype");
    font-weight: 300 900;
    font-display: swap;
    font-style: normal;
}

.hide-scrollbar {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
      display: "none", /* Safari and Chrome */
}

/* Rotating animation */
@keyframes rotating {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
}

.rotating-animation {
    transform-origin: center;
    animation: rotating 2s linear infinite;
}


@keyframes highlight {
  0% {
    background-position: 100% 0;
  }

  50% {
    background-position: 50% 0;
  }

  100% {
    background-position: 100% 0;
  }
}
